"use client";

import { CosmosButton } from "@cosmos/web/react";
import { ButtonWrapper } from "../../ButtonWrapper/ButtonWrapper";
import { TeaserBasic } from "../../TeaserBasic/TeaserBasic";
import classNames from "classnames/bind";
import styles from "./OrganicsTeaser.module.css";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getImageSource } from "../../../helpers/getImageSource";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";

const cx = classNames.bind(styles);

export function OrganicsTeaser({
  kicker,
  title,
  buttonLabel,
  buttonUrl,
  imageUrl,
  imageAlt = title,
}: {
  kicker: string;
  title: string;
  buttonLabel: string;
  buttonUrl: string;
  imageUrl: string;
  imageAlt?: string;
}) {
  const imageLoading = useImageLoading();
  const imageSource = imageUrl ? getImageSource(imageUrl) : null;

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <TeaserBasic
            className={cx("teaser")}
            kicker={kicker}
            title={title}
            titleSize="xx-large"
            appearance="dark"
            headingElement="kicker"
            children={
              <ButtonWrapper className={cx("button-wrapper")}>
                <CosmosButton size="large" href={buttonUrl}>
                  {buttonLabel}
                </CosmosButton>
              </ButtonWrapper>
            }
          />
        </div>
        <div className={cx("image-wrap")}>
          {(() => {
            const imageProps = {
              loading: imageLoading,
              className: cx("img"),
              alt: imageAlt,
            };

            switch (imageSource) {
              case "contentful": {
                return (
                  <img
                    {...imageProps}
                    srcSet={responsiveImageHelper.createContentfulSrcSet(
                      imageUrl,
                    )}
                    sizes="(min-width: 1000px) 454px, 400px"
                  />
                );
              }
              case "storyblok": {
                return (
                  <img
                    {...imageProps}
                    srcSet={responsiveImageHelper.createStoryblokSrcSet(
                      imageUrl,
                    )}
                    sizes="(min-width: 1000px) 454px, 400px"
                  />
                );
              }
              default: {
                return <img {...imageProps} src={imageUrl} />;
              }
            }
          })()}
        </div>
      </div>
    </div>
  );
}
